import ApiUtils from '../../utils/Api';
import Socket from '../../utils/Socket';
import GlobalConfig from "../../utils/GlobalConfig";

const checkCommunityEntries = async (communityRID, user) => {
  const customerContact = await ApiUtils.fetchCustomerContact(
    user.CustomerRID
  );
  const communityEntry = customerContact.find((entry) =>
    entry.CommunityRID === communityRID);
  return communityEntry;
};

const createNewCommunityEntry = async (communityRID, user) => {
  const customerContact = {
    CustomerFID: user.CustomerRID,
    AllowMultipleCommunities: true,
    RegCommunityFID: communityRID,
    SalesRepresentativeFID: 100008,
    WCCustBuyerInfo: {
      ContractName: `${user.NameFirst} ${user.NameLast}`,
      WCBuyers: []
    }
  };
  const updatedEntry = await ApiUtils.saveCustomerInfo(customerContact);
  return updatedEntry;
};

const formatUser = (user) => {
  const formattedUser = {
    CustomerRID: user.CustomerRID,
    EmailHome: user.EmailHome,
    Name: user.Name,
    NameFirst: user.NameFirst,
    NameLast: user.NameLast,
    NameFirst2InputCol: user.NameFirst2InputCol,
    NameLast2InputCol: user.NameLast2InputCol,
    PhoneCell: user.PhoneCell,
    PhoneHome: user.PhoneHome,
    PhoneWork: user.PhoneWork
  };

  return formattedUser;
};

const handleError = (err, setAlert) => {
  if (err.alert) {
    setAlert(err.alert);
  } else {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const initSocket = (roomId, setRoomId, setSocket) => {
  const config = {
    listeners: []
  };

  const newSocket = new Socket(config);
  newSocket.init(roomId, GlobalConfig.get(GlobalConfig.Key.SOCKET_URL));
  newSocket.listen();

  setRoomId(roomId);
  setSocket(newSocket);
};

const parseCommunityRIDs = (communityRIDs) => {
  const result = JSON.parse(communityRIDs);
  return result;
};

export {
  checkCommunityEntries,
  createNewCommunityEntry,
  formatUser,
  handleError,
  initSocket,
  parseCommunityRIDs
};
