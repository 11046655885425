export default class Misc {
  static formatPhoneNumber = (phoneNumber) => {
    const first = phoneNumber.slice(0, 3);
    const second = phoneNumber.slice(3, 6);
    const third = phoneNumber.slice(6, 10);
    const formattedPhoneNumber = `(${first}) ${second}-${third}`;

    return formattedPhoneNumber;
  };
}
