import Login from '../components/Login';
import Register from '../components/Register';

const routeConfig = [
  {
    component: Login,
    path: '/'
  },
  {
    component: Register,
    path: '/register'
  }
];

export default routeConfig;
