import React, { useState } from 'react';
import PropTypes from 'prop-types';
import listDigits from './utils';
import styles from './PhoneInput.module.css';

function PhoneInput({ bindPhoneNumber }) {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <div className={`${styles.PhoneInput} ${hasFocus ? styles.focus : ''}`}>
      <input
        minLength={10}
        maxLength={10}
        name="phone"
        onBlur={() => setHasFocus(false)}
        onChange={bindPhoneNumber.onChange}
        onFocus={() => setHasFocus(true)}
        required
        type="tel"
        value={bindPhoneNumber.value}
      />
      <div className={styles.output}>{listDigits(bindPhoneNumber.value)}</div>
    </div>
  );
}

PhoneInput.propTypes = {
  bindPhoneNumber: PropTypes.object.isRequired
};

export default PhoneInput;
