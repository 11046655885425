import React from 'react';
import { Route } from 'react-router-dom';
import routeConfig from '../../config/routes';

const Routes = (props) => {
  const renderRoute = (route) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      render={() => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <route.component {...props} />
      )}
    />
  );

  const listRoutes = routeConfig.map((route) => renderRoute(route));

  return listRoutes;
};

export default Routes;
