import AlertError from '../../errors/Alert';
import GlobalConfig from "../GlobalConfig";

export default class Api {
  static fetchCustomerInfo = async (phoneNumber) => {
    try {
      const response = await fetch(`${GlobalConfig.get(GlobalConfig.Key.API_URL)}CustomerInfo?phoneHome=${phoneNumber}&kovaEnv=${GlobalConfig.get(GlobalConfig.Key.KOVA_ENV)}`);

      if (!response.ok) {
        const message = `fetchCustomerInfo failed - ${response.status} ${response.statusText}`;
        const alert = {
          heading: 'Error',
          message
        };
        throw new AlertError(alert, message);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      const message = `fetchCustomerInfo failed - ${err.message}`;
      const alert = {
        heading: 'Error',
        message
      };
      throw new AlertError(alert, message);
    }
  }

  static fetchCustomerContact = async (customerRID) => {
    try {
      const response = await fetch(`${GlobalConfig.get(GlobalConfig.Key.API_URL)}CustomerContacts?customerRID=${customerRID}&kovaEnv=${GlobalConfig.get(GlobalConfig.Key.KOVA_ENV)}`);

      if (!response.ok) {
        const message = `fetchCustomerContact failed - ${response.status} ${response.statusText}`;
        const alert = {
          heading: 'Error',
          message
        };
        throw new AlertError(alert, message);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      const message = `fetchCustomerContact failed - ${err.message}`;
      const alert = {
        heading: 'Error',
        message
      };
      throw new AlertError(alert, message);
    }
  }

  static saveCustomerInfo = async (customer) => {
    try {
      const url = `${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}/api/v4/Customer/SaveCustomerInfo?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_PUBLIC_AUTH)}`;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(customer)
      });

      if (!response.ok) {
        const message = `saveCustomerInfo failed - ${response.status} ${response.statusText}`;
        const alert = {
          heading: 'Error',
          message
        };
        throw new AlertError(alert, message);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      const message = `saveCustomerInfo failed - ${err.message}`;
      const alert = {
        heading: 'Error',
        message
      };
      throw new AlertError(alert, message);
    }
  }

  static updateCustomerData = async (customerData) => {
    try {
      const url = `${GlobalConfig.get(GlobalConfig.Key.API_URL)}UserDataValues?kovaEnv=${GlobalConfig.get(GlobalConfig.Key.KOVA_ENV)}`;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(customerData)
      });

      if (!response.ok) {
        const message = `updateCustomerData failed - ${response.status} ${response.statusText}`;
        const alert = {
          heading: 'Error',
          message
        };
        throw new AlertError(alert, message);
      }

      return response;
    } catch (err) {
      const message = `updateCustomerData failed - ${err.message}`;
      const alert = {
        heading: 'Error',
        message
      };
      throw new AlertError(alert, message);
    }
  }

  static async fetchJSONFile(key) {
    const response = await fetch(
      `${GlobalConfig.get(GlobalConfig.Key.API_URL)}AppFiles/config/${GlobalConfig.get(GlobalConfig.Key.CONFIG_FOLDER)}/${key}`
    );
    const json = await response.json();

    return json;
  }
}
