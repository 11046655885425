import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

function Button({
  background,
  clickHandler,
  form,
  text,
  type
}) {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={styles.Button}
      form={form}
      onClick={clickHandler}
      style={{ background }}
      type={type}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  background: '#191919',
  clickHandler: null,
  form: null,
  type: 'button'
};

Button.propTypes = {
  background: PropTypes.string,
  clickHandler: PropTypes.func,
  form: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default Button;
