import React from 'react';
import PropTypes from 'prop-types';
import styles from './Digit.module.css';

function Digit({ value }) {
  return (
    <div className={styles.Digit}>
      <div>{value}</div>
      <div />
    </div>
  );
}

Digit.defaultProps = {
  value: ''
};

Digit.propTypes = {
  value: PropTypes.string
};

export default Digit;
