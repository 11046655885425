const alerts = {
  critical: {
    communityRID: {
      heading: 'Error',
      message: 'communityRID is required.'
    },
    loginAction: {
      heading: 'Error',
      message: 'loginAction is required'
    },
    roomId: {
      heading: 'Error',
      message: 'roomId is required'
    },
    saleCenterId: {
      heading: 'Error',
      message: 'saleCenterId is required'
    }
  },
  validation: {
    email: {
      heading: 'Validation Error',
      message: 'Emails must match.'
    }
  }
};

export default alerts;
