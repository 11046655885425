import React from 'react';
import PropTypes from 'prop-types';
import styles from './Alert.module.css';

function Alert({ alert }) {
  return (
    <div className={styles.Alert}>
      <h1>{`${alert.heading}:`}</h1>
      <div>{alert.message}</div>
    </div>
  );
}

Alert.propTypes = {
  alert: PropTypes.object.isRequired
};

export default Alert;
