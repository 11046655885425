import React from 'react';
import PropTypes from 'prop-types';
import MiscUtils from '../../utils/Misc';
import Button from '../Button';
import styles from './Register.module.css';
import warningIcon from '../../img/Icon_Warning.svg';

function Register({
  bindFirstName,
  bindMiddleInitial,
  bindLastName,
  bindEmail,
  bindEmailConfirm,
  handleRegister,
  handleRegisterCancel,
  phoneNumber
}) {
  return (
    <div className={styles.Register}>
      <img alt="warning" src={warningIcon} />
      <h1>You must be new here!</h1>
      <div className={styles.message}>
        It doesn&apos;t look like we&apos;ve seen you before. Please enter your
        information below. We won&apos;t share it with anyone.
      </div>
      <h2>{`Phone Number: ${phoneNumber && MiscUtils.formatPhoneNumber(phoneNumber)}`}</h2>
      <form id="form-register" onSubmit={handleRegister}>
        <div>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindFirstName}
            required
            minLength={1}
            name="firstName"
            placeholder="Enter First Name"
            type="text"
          />
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindMiddleInitial}
            maxLength={2}
            minLength={1}
            name="middleInitial"
            placeholder="M"
            type="text"
          />
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindLastName}
            required
            minLength={1}
            name="lastName"
            placeholder="Enter Last Name"
            type="text"
          />
        </div>
        <div>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindEmail}
            required
            minLength={1}
            name="email"
            placeholder="Enter Email"
            type="email"
          />
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindEmailConfirm}
            required
            minLength={1}
            name="emailConfirm"
            placeholder="Confirm Email"
            type="email"
          />
        </div>
      </form>
      <div>
        <Button
          background="#1E8ADC"
          form="form-register"
          text="sign in"
          type="submit"
        />
        <Button
          clickHandler={handleRegisterCancel}
          text="cancel"
        />
      </div>
    </div>
  );
}

Register.propTypes = {
  bindFirstName: PropTypes.object.isRequired,
  bindMiddleInitial: PropTypes.object.isRequired,
  bindLastName: PropTypes.object.isRequired,
  bindEmail: PropTypes.object.isRequired,
  bindEmailConfirm: PropTypes.object.isRequired,
  handleRegister: PropTypes.func.isRequired,
  handleRegisterCancel: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

export default Register;
