class GlobalConfig {
  constructor() {
    this.config = {};
    this.Key = {
      BASE_URL: 'BASE_URL',
      SOCKET_URL: 'SOCKET_URL',
      API_URL: 'API_URL',
      KOVA_ENV: 'KOVA_ENV',
      KOVA_PUBLIC_AUTH: 'KOVA_PUBLIC_AUTH',
      KOVA_URL: 'KOVA_URL',
      CONFIG_FOLDER: 'CONFIG_FOLDER'
    };
  }

  setConfig(config) {
    this.config = config;
  }

  get(key) {
    return this.config[key];
  }
}

const configInstance = new GlobalConfig();

export default configInstance;
