import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import PhoneInput from '../PhoneInput';

import styles from './Login.module.css';
import logo from '../../img/Logo_Oakwood_Sign_In.svg';
import oakwoodLifeLogo from '../../img/Logo_OakwoodLife.png';

function Login({
  bindPhoneNumber,
  handleSignIn,
  roomId,
  socket,
  saleCenter
}) {
  return (
    <div className={styles.Login}>
      <img src={(saleCenter && saleCenter.isActiveAdult) ? oakwoodLifeLogo : logo} alt="Oakwood Homes" />
      <h1>Register / Check-in Here!</h1>
      <div className={styles.message}>Please enter your phone number</div>
      <form id="form-login" onSubmit={handleSignIn}>
        <PhoneInput bindPhoneNumber={bindPhoneNumber} />
      </form>
      <div>
        <Button
          background="#1E8ADC"
          form="form-login"
          text="sign in"
          type="submit"
        />
        <Button
          clickHandler={() => socket.emit('cancel login', { name: 'cancel login', roomId })}
          text="cancel"
        />
      </div>
    </div>
  );
}

Login.propTypes = {
  bindPhoneNumber: PropTypes.object.isRequired,
  handleSignIn: PropTypes.func.isRequired,
  roomId: PropTypes.string.isRequired,
  socket: PropTypes.object.isRequired
};

export default Login;
