import React from 'react';
import Digit from './Digit';

const listDigits = (phoneNumber) => {
  const phoneNumberArr = phoneNumber.split('');

  return [...Array(10)].map((x, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Digit key={i} value={phoneNumberArr[i]} />
  ));
};

export default listDigits;
