import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Switch } from 'react-router-dom';
import queryString from 'query-string';
import useInput from '../../hooks/input';
import ApiUtils from '../../utils/Api';
import MiscUtils from '../../utils/Misc';
import alertConfig from '../../config/alerts';
import {
  checkCommunityEntries,
  createNewCommunityEntry,
  formatUser,
  handleError,
  initSocket,
  parseCommunityRIDs
} from './utils';

import Alert from '../Alert';
import LoadingOverlay from '../LoadingOverlay';
import Routes from '../Routes';

import styles from './App.module.css';

function App() {
  // const [phoneNumber, setPhoneNumber] = useState('');
  const { value: phoneNumber, bind: bindPhoneNumber, reset: resetPhoneNumber } = useInput('');
  const { value: firstName, bind: bindFirstName, reset: resetFirstName } = useInput('');
  const { value: middleInitial, bind: bindMiddleInitial, reset: resetMiddleInitial } = useInput('');
  const { value: lastName, bind: bindLastName, reset: resetLastName } = useInput('');
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
  const { value: emailConfirm, bind: bindEmailConfirm, reset: resetEmailConfirm } = useInput('');
  const [communityRIDs, setCommunityRIDs] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState(null);
  const [socket, setSocket] = useState(null);
  const [status, setStatus] = useState('');
  const [saleCenter, setSaleCenter] = useState(null);

  const history = useHistory();
  const location = useLocation();

  async function fetchData(salesCenterId) {
    try {
      const salesCenterConfig = await ApiUtils.fetchJSONFile('salesCenter.json');
      const selectedSalesCenter = salesCenterConfig.find((salesCenter) =>
        salesCenter.salesCenterId === Number(salesCenterId));

      setSaleCenter(selectedSalesCenter);
    } catch (err) {
      console.error('fetch JSON error:', err);
    }
  }

  useEffect(() => {
    setAlert(null);
  }, [location]);

  useEffect(() => {
    const query = queryString.parse(window.location.search);

    if (query.communityRID) {
      const communityRIDArr = parseCommunityRIDs(query.communityRID);
      if (Array.isArray(communityRIDArr)) {
        setCommunityRIDs(communityRIDArr);
      } else {
        setCommunityRIDs([Number(query.communityRID)]);
      }
    } else {
      setAlert(alertConfig.critical.communityRID);
    }

    if (query.roomId) {
      initSocket(query.roomId, setRoomId, setSocket);
    } else {
      setAlert(alertConfig.critical.roomId);
    }

    if (query.saleCenterId) {
      fetchData(query.saleCenterId);
    } else {
      setAlert(alertConfig.critical.saleCenterId);
    }
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          // eslint-disable-next-line no-restricted-syntax
          for (const communityRID of communityRIDs) {
            setStatus('checking community entry...');
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });
            // eslint-disable-next-line no-await-in-loop
            const communityEntry = await checkCommunityEntries(
              communityRID,
              user
            );
            if (communityEntry) {
              setStatus('community entry found...');
              // eslint-disable-next-line no-await-in-loop
              await new Promise((resolve) => {
                setTimeout(resolve, 1000);
              });
            } else {
              // No match
              setStatus('generating new community entry...');
              // eslint-disable-next-line no-await-in-loop
              await new Promise((resolve) => {
                setTimeout(resolve, 1000);
              });
              // eslint-disable-next-line no-await-in-loop
              await createNewCommunityEntry(
                communityRID,
                user
              );
            }
          }

          const event = {
            name: 'login',
            roomId,
            data: {
              customerRID: user.CustomerRID,
              emailHome: user.EmailHome,
              name: user.Name,
              nameFirst: user.NameFirst,
              nameLast: user.NameLast,
              nameFirst2: user.NameFirst2InputCol,
              nameLast2: user.NameLast2InputCol,
              phoneCell: user.PhoneCell,
              phoneHome: user.PhoneHome,
              phoneWork: user.PhoneWork
            }
          };
          console.log(event);
          socket.emit('login', event);
          setStatus('');
        } catch (err) {
          handleError(err, setAlert);
        }
        resetPhoneNumber();
      })();
    }
  }, [user]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setAlert(null);
    setStatus('signing-in...');

    try {
      const formattedPhoneNumber = MiscUtils.formatPhoneNumber(phoneNumber);
      const customerInfo = await ApiUtils.fetchCustomerInfo(
        formattedPhoneNumber
      );

      if (customerInfo.length === 0) {
        setStatus('');
        history.push('/register');
      } else {
        setStatus('customer found...');
        const sortedCustomers = customerInfo.sort((a, b) =>
          new Date(a.CreationDate) - new Date(b.CreationDate));
        const formattedUser = formatUser(sortedCustomers[0]);
        setUser(formattedUser);
      }
    } catch (err) {
      handleError(err, setAlert);
    }
  };

  const getNewCustomerInfo = async (newCustomerPhoneNumber) => {
    const customerInfo = await ApiUtils.fetchCustomerInfo(
      newCustomerPhoneNumber
    );
    if (customerInfo.length === 0) {
      return getNewCustomerInfo();
    }

    return customerInfo;
  };

  const generateCustomFields = async (customerRID) => {
    const customFieldDataBool = {
      RefObjType: 'Customer',
      RefObjRID: customerRID,
      FieldID: 'Bool_ERCRegCard1',
      BoolValue: true
    };
    const customFieldDataTextHousing = {
      RefObjType: 'Customer',
      RefObjRID: customerRID,
      FieldID: 'Text_ERCHousing1',
      TextValue: ''
    };
    const customFieldDataTextMoving = {
      RefObjType: 'Customer',
      RefObjRID: customerRID,
      FieldID: 'Text_ERCMoving1',
      TextValue: ''
    };
    await ApiUtils.updateCustomerData(customFieldDataBool);
    await ApiUtils.updateCustomerData(customFieldDataTextHousing);
    await ApiUtils.updateCustomerData(customFieldDataTextMoving);

    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    setStatus('generating new customer...');
    await new Promise((resolve) => {
      setTimeout(resolve, 500);
    });

    if (email !== emailConfirm) {
      setAlert(alertConfig.validation.email);
      return;
    }

    const WCCustBuyerInfo = {
      ContractName: `${firstName} ${lastName}`,
      WCBuyers: []
    };
    const Customer = {
      WCCustBuyerInfo,
      Name: `${lastName}, ${firstName}`,
      NameLast: lastName,
      NameFirst: firstName,
      PhoneHome: MiscUtils.formatPhoneNumber(phoneNumber),
      EmailHome: email,
      RegCommunityFID: communityRIDs[0],
      SalesRepresentativeFID: 100008
    };

    if (middleInitial) {
      Customer.NameMiddle = middleInitial;
    }

    try {
      const newCustomer = await ApiUtils.saveCustomerInfo(Customer);
      const customerInfo = await getNewCustomerInfo(newCustomer.PhoneHome);
      const formattedUser = formatUser(customerInfo[0]);
      const customerRID = customerInfo[0].CustomerRID;
      await generateCustomFields(customerRID);
      setUser(formattedUser);
      setStatus('');
    } catch (err) {
      handleError(err, setAlert);
    }
    resetFirstName();
    resetMiddleInitial();
    resetLastName();
    resetEmail();
    resetEmailConfirm();
  };

  const handleRegisterCancel = () => {
    history.push(`/?communityRID=[${communityRIDs}]&roomId=${roomId}`);
    resetPhoneNumber();
  };

  return (
    <div className={styles.App}>
      <div className={styles.wrapper}>
        {alert && <Alert alert={alert} />}
        {communityRIDs && roomId && (
          <Switch>
            <Routes
              bindEmail={bindEmail}
              bindEmailConfirm={bindEmailConfirm}
              bindFirstName={bindFirstName}
              bindLastName={bindLastName}
              bindMiddleInitial={bindMiddleInitial}
              bindPhoneNumber={bindPhoneNumber}
              handleRegister={handleRegister}
              handleRegisterCancel={handleRegisterCancel}
              handleSignIn={handleSignIn}
              phoneNumber={phoneNumber}
              roomId={roomId}
              socket={socket}
              saleCenter={saleCenter}
            />
          </Switch>
        )}
        {status && !alert && <LoadingOverlay status={status} />}
      </div>
    </div>
  );
}

export default App;
